import { Calendar } from 'fullcalendar/dist/fullcalendar.min';

export class CalendarSetup {
  static setup() {
    let calendarEl = $('#calendar')
    if (calendarEl.length) {
      let calendar = new Calendar(calendarEl, {
        events: '/appointments/calendar.json' + window.location.search,
        eventTextColor: 'black',
        eventRender(event, element) {
          if (event.background_color) {
            element.css('background-color', event.background_color);
            element.css('font-size', '1.2em');
          }
          if (event.icon) {
            element.find('.fc-title').prepend(`<i class='${event.icon} mr-2 ml-2'></i>`);
          }
          $(element).tooltip({title: event.description, container: 'body'});
        },
        header: {
          left: 'prev,next today',
          center: 'title',
          right: 'month,agendaWeek,agendaDay'
        },
        loading(isLoading, view){
          CalendarSetup.updateLoadingStatus(isLoading);
        },
        views: {
          month: {
            displayEventEnd: true
          }
        },
        startParam: 'start_time',
        endParam: 'end_time'
      });
      calendar.render();
    } else {
      CalendarSetup.loaded();
    }
  }

  static updateLoadingStatus(isLoading) {
    if (isLoading) {
      return CalendarSetup.loading();
    } else {
      return CalendarSetup.loaded();
    }
  }

  static loaded() {
    $('#calendar-view-tab .loaded').show();
    $('#calendar-view-tab .loading').hide();
  }

  static loading() {
    $('#calendar-view-tab .loaded').hide();
    $('#calendar-view-tab .loading').show();
  }
}

